<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import UserDetail from './UserDetail'
/**
 * Elements component
 */
export default {
  page: {
    title: "User Detail",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, UserDetail },
  data() {
    return {
      title: "Update User",
      items: [
        {
          text: "User",
          href: "/user/list",
        },
        {
          text: "Update",
          active: true,
        },
      ],
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <User-detail :is-edit="true" />
  </Layout>
</template>
