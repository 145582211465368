<template>
    <div class="row">
      <div class="col-lg-12">
        <b-overlay :show="loading" rounded="sm">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-3">{{isEdit ? $t('update') : $t('register')}} {{$t('user')}}
                <button v-show="isEdit && !lock_deleteBtn" :disabled="lock_deleteBtn" class="btn btn-outline-danger btn-sm ms-1" @click="deleteUser">DELETE</button>
              </h4>

              <div class="row">
                <div class="col-lg-6">
                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group
                        label="User ID / 用户代号"
                        label-for="form-user-id"
                        class="mb-3"
                      >
                        <b-form-input
                          type="text"
                          id="form-user-id"
                          :disabled="lock_editBtn"
                          v-model="user.username"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group
                        label="User Level / 用户级别"
                        label-for="form-user-level"
                        class="mb-3"
                      >
                        <select label-for="form-user-level" class="form-select" v-model="user.level">
                          <option value="" disabled>Please select</option>
                          <option value="SUPERADMIN" v-show="compute_user.role == 'SUPERADMIN' ">SUPER ADMIN</option>
                          <option value="ADMIN" v-show="compute_user.role == 'SUPERADMIN' || compute_user.role == 'ADMIN'">ADMIN</option>
                          <option value="COLLECTOR" v-show="compute_user.role == 'SUPERADMIN' || compute_user.role == 'ADMIN' || compute_user.role == 'COLLECTOR'">COLLECTOR</option>
                        </select>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="row" v-show="!isEdit">
                    <div class="col-md-6">
                      <b-form-group
                        label="Password / 密码"
                        label-for="form-password"
                        class="mb-3"
                      >
                        <b-form-input
                          id="form-password"
                          type="password"
                          v-model="user.password"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group
                        label="Confirm Password / 确认密码"
                        label-for="form-reenter-password"
                        class="mb-3"
                      >
                        <b-form-input
                          id="form-reenter-password"
                          type="password"
                          v-model="user.repassword"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>

                  <b-form-group
                    label="User Name / 用户姓名"
                    label-for="form-user-name"
                    class="mb-3"
                  >
                    <b-form-input
                      id="form-user-name"
                      type="text"
                      v-model="user.fullname"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Company Code / 公司代号"
                    label-for="form-user-company-code"
                    class="mb-3"
                  >
                  <textarea id="form-user-company-code" v-model="user.company_code" class="form-control" rows="3" placeholder=""></textarea>
                  </b-form-group>

                  <b-form-group
                    label="Group Code / 组合代号"
                    label-for="form-user-group-code"
                    class="mb-3"
                  >
                  <textarea id="form-user-group-code" v-model="user.group_code" class="form-control" rows="3" placeholder=""></textarea>
                  </b-form-group>

                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group
                        label="Contact No / 联系方式"
                        label-for="form-user-contact1"
                        class="mb-3"
                      >
                        <b-form-input
                          id="form-user-contact1"
                          type="text"
                          v-model="user.contact1"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group
                        label="Other Contact No / 其他联系方式"
                        label-for="form-user-contact2"
                        class="mb-3"
                      >
                        <b-form-input
                          id="form-user-contact2"
                          type="text"
                          v-model="user.contact2"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>

                  <b-form-group
                    label="Email / 电邮"
                    label-for="form-user-email"
                    class="mb-3"
                  >
                    <b-form-input
                      id="form-user-email"
                      type="text"
                      v-model="user.email"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Remark / 备忘录"
                    label-for="form-user-remark"
                    class="mb-3"
                  >
                  <textarea id="form-user-remark" v-model="user.remark" class="form-control" rows="3" placeholder=""></textarea>
                  </b-form-group>

                  <div class="row" v-show="isEdit">
                    <div class="col-md-6">
                      <b-form-group
                        label="Status / 状态"
                        label-for="form-user-status"
                        class="mb-3"
                      >
                        <select label-for="form-user-status" class="form-select" v-model="user.status">
                          <option value="ACTIVE">ACTIVE</option>
                          <option value="INACTIVE">INACTIVE</option>
                          <option value="DELETED">DELETED</option>
                        </select>
                      </b-form-group>
                    </div>
                  </div>
                  

                </div>
              </div>

              <hr v-show="isEdit">
              <div class="row" v-show="isEdit">
                <div class="col-lg-12">
                  <h6>Permissions</h6>
                  <div class="row">
                    <div class="mt-2 col-lg-4" v-for="item in permission_list" :key="item.id" v-show="compute_user.permissions.includes('*') || arr_pms.includes(item.new_id)">
                      <b-form-checkbox
                        :value="item.new_id"
                        unchecked-value="0"
                        v-model="arr_pms"
                        :disabled="!compute_user.permissions.includes('*')"
                        plain
                        >{{ item.desc }}</b-form-checkbox
                      >
                    </div>
                  </div>
                  
                  <!-- <div class="card" style="border: 1px solid #f6f6f6">
                    <div class="card-body">
                      <div class="card-title">
                        Permissions
                      </div>
                      <div class="custom-accordion">
                        <div class="p-4 border-top">
                          <h5 class="font-size-14 mb-0">
                            <a
                              href="javascript: void(0);"
                              class="text-dark d-block"
                              data-toggle="collapse"
                              v-b-toggle.filtersizes-collapse1
                            >
                              User
                              <i
                                class="mdi mdi-chevron-up float-end accor-down-icon"
                              ></i>
                            </a>
                          </h5>

                          <b-collapse id="filtersizes-collapse1">
                            <div class="mt-2">
                              <b-form-checkbox
                                value="accepted"
                                unchecked-value="not_accepted"
                                v-model="rightcheck"
                                plain
                                >List</b-form-checkbox
                              >
                            </div>
                            <div class="mt-2">
                              <b-form-checkbox
                                value="accepted"
                                unchecked-value="not_accepted"
                                v-model="rightcheck"
                                plain
                                >Create</b-form-checkbox
                              >
                            </div>
                            <div class="mt-2">
                              <b-form-checkbox
                                value="accepted"
                                unchecked-value="not_accepted"
                                v-model="rightcheck"
                                plain
                                >Edit</b-form-checkbox
                              >
                            </div>
                            <div class="mt-2">
                              <b-form-checkbox
                                value="accepted"
                                unchecked-value="not_accepted"
                                v-model="rightcheck"
                                plain
                                >Delete</b-form-checkbox
                              >
                            </div>
                          </b-collapse>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
              <hr v-show="isEdit">
               <div class="row" v-show="isEdit">
                <div class="col-lg-6">
                  <h6>Granted Access</h6>
                  <table class="table table-bordered text-center" style="width:75%">
                    <thead>
                      <tr>
                        <th width="50%">Select User</th>
                        <th width="20%">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <multiselect :disabled="!compute_user.permissions.includes('*')" v-model="granted_user" :options="user_list" label="name" track-by="uuid" :multiple="false" :searchable="true" :max-height="600" :show-no-results="true" :hide-selected="false">

                            <span slot="noResult">Oops! No result found.</span>
                          </multiselect>
                        </td>
                        <td>
                          <button :disabled="!compute_user.permissions.includes('*')" class="btn btn-primary btn-sm" @click="addGranted"><i class="fas fa-plus"></i></button>
                        </td>
                      </tr>
                      <tr v-for="(item,index) in granted_user_list" :key="index">
                        <td>{{item.granted_user.name || '-' }}</td>
                        <td><button class="btn btn-danger btn-sm" :disabled="!compute_user.permissions.includes('*')" @click="removeGranted(index)"><i class="fas fa-trash-alt"></i></button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <button class="btn btn-primary" @click="submit" :disabled="lock_createBtn && lock_editBtn" v-show="!lock_createBtn">
                {{isEdit ? 'Update' : 'Create' }} <i class="far fa-save p-1"></i>
              </button>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
</template>

<script>
  import Multiselect from "vue-multiselect";
  export default {
    name: 'UserDetail',
    components: { Multiselect },
    props: {
      isEdit: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        loading: false,
        user:{
          username: '',
          level: '',
          password: '',
          repassword: '',
          fullname: '',
          company_code: '',
          group_code: '',
          contact1: '',
          contact2: '',
          email: '',
          remark: '',
          status: ''
        },
        rightcheck: false,

        current_id: 0,

        permission_list:[],
        arr_pms: [],

        user_list:[],
        granted_user: '',
        granted_user_list: [],
      }
    },
    computed:{
      compute_user(){
        return this.$store.state.authfack.user
      },
      lock_createBtn(){
        if(this.compute_user.permissions.includes("*") || this.compute_user.permissions.includes('011') || this.compute_user.permissions.includes('012') ){
          // console.log('create_user has permissions allowed')
          return false
        }
        else{
          // console.log('create_user no permissions')
          return true
        }
      },
      lock_deleteBtn(){
        if(this.compute_user.permissions.includes("*") || this.compute_user.permissions.includes('013') ){
          // console.log('delete user has permissions allowed')
          return false
        }
        else{
          // console.log('delete user no permissions')
          return true
        }
      },
      lock_editBtn(){
        if(this.compute_user.permissions.includes("*") || this.compute_user.permissions.includes('012') ){
          // console.log('update user info has permissions allowed')
          return false
        }
        else{
          // console.log('update user info no permissions')
          return true
        }
      },
    },
    created(){      
      if (this.isEdit) {
        this.current_id = this.$route.params && this.$route.params.id
        this.load_detail()

        this.load_permission_list()
        this.load_all_users()
        this.load_granted_list()
      }
    },
    methods: {
      minThreeDigits(n) {
        return (n < 100 ? '0' : '') + n;
      },
      load_all_users(){
        let self = this
        const baseURI = 'users/list'
        let formData = new FormData();
        this.user_list = []
        formData.append('val', '');
        formData.append('status', 'ACTIVE');

        this.$http.post(baseURI,formData)
        .then((result) => {
          if(result.data.status == 'success'){
            result.data.data.forEach(function(item){
              // console.log(item)
              if(item.uuid !== self.current_id){
                self.user_list.push({
                  uuid: item.uuid,
                  name: item.name || '-'
                })
              }
              
            })
          }
        })
        .catch((err) => {
          if(err == 'session_timeout'){
            this.$notify({
              group: 'auth',
              title: 'User Authentication',
              text: 'Your session has expired, please login again',
              type: 'warn'
            });
            this.logout();
            this.$router.push({
              path: "/login",
            });
          }
        })
      },
      load_permission_list(){
        let self = this
        self.permission_list = []
        const baseURI = 'permissions'
        self.$http.get(baseURI)
        .then((result) => {
          if(result.data.status == 'success'){
            // let title_array = []
            console.log(result.data.data)
            result.data.data.forEach(function(item){
              if(item.object !== 'log'){
                item.new_id = self.minThreeDigits(item.id)
                self.permission_list.push(item)

                // if(self.compute_user.permissions.includes("*")){
                //   self.arr_pms.push(item.new_id)
                // }
              }
              
            })

          }
        })
      },
      addGranted(){
        let self = this
        console.log('add granted')
        if(self.granted_user_list.length !== 0){
          console.log('22 granted')
          self.granted_user_list.forEach(function(item){
            if(item.grant_to_user_uuid == self.granted_user.uuid){
              alert("already added")
            }
          })
        }
        else{
          console.log('11 granted')
          const baseURI = 'user_users'
          let formData = new FormData()
          formData.append('user_uuid', this.current_id)
          formData.append('grant_to_user_uuid', this.granted_user.uuid)
          self.$http.post(baseURI, formData)
          .then((result) => {
            if(result.data.status == 'success'){
              self.granted_user = ''
              self.$notify({
                group: 'app',
                title: `Granted user success`,
                type: 'success'
              });
              self.load_granted_list()
            }
          })
        }
      },
      removeGranted(index){
        console.log(index)
        let self = this
        const baseURI = 'user_users/'+self.granted_user_list[index].id
        self.$http.delete(baseURI)
        .then((result) => {
          if(result.data.status == 'success'){
            self.load_granted_list()
          }
        })
      },
      load_granted_list(){
        let self = this
        self.granted_user_list = []
        const baseURI = 'user_users/list'
        let formData = new FormData()
        formData.append('user_uuid', this.current_id)
        self.$http.post(baseURI, formData)
        .then((result) => {
          if(result.data.status == 'success'){
            console.log('granted list ',result.data.data)
            self.granted_user_list = result.data.data
          }
        })
      },
      load_detail(){
        let self = this
        self.loading = true
        const baseURI = 'users/'+self.current_id
        self.$http.get(baseURI)
        .then((result) => {
          if(result.data.status == 'success'){
            console.log('pubo',result.data.data)
            self.loading = false
            self.user.username = result.data.data.username
            self.user.fullname = result.data.data.name
            self.user.level = result.data.data.role
            self.user.company_code = result.data.data.company_code
            self.user.group_code = result.data.data.group_code
            self.user.contact1 = result.data.data.primary_contact
            self.user.contact2 = result.data.data.secondary_contact
            self.user.email = result.data.data.email
            self.user.remark = result.data.data.remark
            self.user.status = result.data.data.status

            console.log(result.data.data.permissions, !result.data.data.permissions.includes('*'))
            if(!result.data.data.permissions.includes('*')){
              if(result.data.data.permissions !== ''){
                self.arr_pms = result.data.data.permissions.split(',')
              }
              
              // .map( Number )
            }
            
          }
        })
      },
      deleteUser(){
        let self = this

        var r = confirm('Delete this user?');
        if (r == true) {
          self.loading = true
          // https://www.maxwell777.com/dev/api/v1/
          const baseURI = 'users/'+self.current_id
          self.$http.delete(baseURI)
          .then((result) => {
            console.log(result)
            if(result.data.status == 'success'){
              self.loading = false
              self.$notify({
                group: 'app',
                title: `Account deleted`,
                type: 'success'
              });
              self.$router.push({
                path: "/user/list",
              });
            }
          })
          .catch((err) => {
            if(err == 'session_timeout'){
              self.logout();
              self.$router.push({
                path: "/login",
              });
            }
          })
        }
      },
      submit(){
        let self = this
        if(self.current_id == 0 || self.current_id == '0'){
          if(self.user.password !== self.user.repassword ){
            alert('Password mismatch')
            return false
          }
          else{
            self.loading = true
            const baseURI = 'users'
            let formData = new FormData();
            
            formData.append('username', self.user.username )
            formData.append('password', self.user.password )
            formData.append('name', self.user.fullname )
            formData.append('role', self.user.level )
            formData.append('group_code', self.user.group_code )
            formData.append('company_code', self.user.company_code )
            formData.append('primary_contact', self.user.contact1 )
            formData.append('secondary_contact', self.user.contact2 )
            formData.append('email', self.user.email )
            formData.append('remark', self.user.remark )
            formData.append('permissions', self.arr_pms )
            formData.append('status', 'ACTIVE')
            formData.append('permissions', '' )

            self.$http.post(baseURI,formData)
            .then((result) => {
              if(result.data.status == 'success'){
                self.loading = false

                self.$notify({
                  group: 'auth',
                  title: 'Register successful',
                  type: 'success'
                });

                self.$router.push({
                  path: "/user/list",
                });
              }
            })
          }
        }
        else{
          //update
          self.loading = true
          const baseURI = 'users/'+self.current_id
          let formData = new FormData();          
          formData.append('_method', 'PUT' )
          formData.append('username', self.user.username )
          formData.append('name', self.user.fullname )
          formData.append('role', self.user.level )
          formData.append('group_code', self.user.group_code )
          formData.append('company_code', self.user.company_code )
          formData.append('primary_contact', self.user.contact1 )
          formData.append('secondary_contact', self.user.contact2 )
          formData.append('email', self.user.email )
          formData.append('remark', self.user.remark )
          formData.append('status', self.user.status )
          formData.append('permissions', self.arr_pms )

          self.$http.post(baseURI,formData)
          .then((result) => {
            if(result.data.status == 'success'){
              self.loading = false
              self.$notify({
                  group: 'auth',
                  title: 'Update successful',
                  type: 'success'
                });
              self.load_detail()
            }
          })
        }
      }
    }
  }
  </script>